<template>
  <design-page class="DesignPageAboutUs">
    <div class="BodyContactUsSolutions">
      <div class="TitleHeaderContactUs" v-if="CardContainerIsActive">
        <h1>¡Ven Por El Tuyo!</h1>
      </div>
      <div class="CardCenterContainer" v-if="CardContainerIsActive">
        <form
          id="Cards"
          class="card"
          form
          v-on:submit.prevent="checkIfRecaptchaVerified"
        >
          <div id="Left">
            <div class="ContName InputControl">
              <div
                class="form-group"
                :class="{ 'form-group--error': $v.name.$error }"
              >
                <input
                  id="inputName"
                  class="input is-rounded"
                  type="text"
                  required
                  autocomplete="off"
                  placeholder="Nombre Completo"
                  v-model.trim="$v.name.$model"
                  v-on:input="$v.name.$touch"
                  v-bind:class="{
                    error: $v.name.$error,
                    valid: $v.name.$dirty && !$v.name.$invalid,
                  }"
                />
              </div>
              <div class="errorName" v-if="!$v.name.required"></div>
              <div class="errorName" v-if="!$v.name.minLength">
                El nombre debe tener al menos
                {{ $v.name.$params.minLength.min }} letras
              </div>
            </div>
            <div class="ContLastName InputControl">
              <div
                class="form-group"
                :class="{ 'form-group--error': $v.lastname.$error }"
              >
                <input
                  id="inputLastName"
                  class="input is-rounded"
                  type="text"
                  required
                  autocomplete="off"
                  placeholder="Apellidos"
                  v-model.trim="$v.lastname.$model"
                  v-on:input="$v.lastname.$touch"
                  v-bind:class="{
                    error: $v.lastname.$error,
                    valid: $v.lastname.$dirty && !$v.lastname.$invalid,
                  }"
                />
              </div>
              <div class="errorLastName" v-if="!$v.lastname.required"></div>
              <div class="errorLastName" v-if="!$v.lastname.minLength">
                El apellido debe tener al menos
                {{ $v.lastname.$params.minLength.min }} letras
              </div>
            </div>
            <div class="ContPhone  InputControl">
              <div
                class="form-group"
                :class="{ 'form-group--error': $v.phone.$error }"
              >
                <input
                  id="inputPhone"
                  class="input is-rounded"
                  type="text"
                  required
                  autocomplete="off"
                  placeholder="Telefono"
                  v-model.trim="$v.phone.$model"
                  v-on:input="$v.phone.$touch"
                  v-bind:class="{
                    error: $v.phone.$error,
                    valid: $v.phone.$dirty && !$v.phone.$invalid,
                  }"
                />
              </div>
              <div class="errorPhone" v-if="!$v.phone.required"></div>
              <div class="errorPhone" v-if="!$v.phone.minLength">
                Debe contener al menos
                {{ $v.phone.$params.minLength.min }} digitos
              </div>
            </div>
          </div>

          <div id="Right">
            <div class="ContEmail InputControl">
              <div
                class="form-group"
                :class="{ 'form-group--error': $v.email.$error }"
              >
                <input
                  id="inputEmail"
                  class="input is-rounded"
                  type="text"
                  required
                  autocomplete="off"
                  placeholder="Correo electronico"
                  v-model.trim="$v.email.$model"
                  v-on:input="$v.email.$touch"
                  v-bind:class="{
                    error: $v.email.$error,
                    valid: $v.email.$dirty && !$v.email.$invalid,
                  }"
                />
              </div>
              <div class="errorEmail" v-if="!$v.email.required"></div>
              <div class="errorEmail" v-if="!$v.email.email">
                El correo no es valido{{ $v.email.$params.email.email }}
              </div>
            </div>
            <div class="ContMessage InputControl">
              <div
                class="form-group"
                :class="{ 'form-group--error': $v.message.$error }"
              >
                <input
                  id="inputMessage"
                  class="input is-rounded"
                  type="text"
                  autocomplete="off"
                  placeholder="Mensaje"
                  required
                  v-model.trim="$v.message.$model"
                  v-on:input="$v.message.$touch"
                  v-bind:class="{
                    error: $v.message.$error,
                    valid: $v.message.$dirty && !$v.message.$invalid,
                  }"
                />
              </div>
              <div class="errorMessage" v-if="!$v.message.required"></div>
              <div class="errorMessage" v-if="!$v.message.maxLength">
                El mensaje no debe icnluir mas de
                {{ $v.message.$params.maxLength.max }} letras
              </div>
            </div>
            <div class="UnderFormContactUs">
              <vue-recaptcha
                id="Recaptcha"
                :sitekey="sitekey"
                vue-recaptcha
                @verify="markRecaptchaAsVerified"
              />
              <button id="Button" class="button is-rounded" type="submit">
                ¡Enviar Solicitud!
              </button>
            </div>
          </div>
        </form>
        <div class="Msg">
          <strong>{{ loginForm.pleaseTickRecaptchaMessage }}</strong>
        </div>
      </div>
      <div class="ContainerNoticeViewExit" v-if="ContainerNoticeIsActive">
        <div class="TitleHeaderExitView">
          <h1>¡Vuelva Pronto!</h1>
        </div>
        <div class="courses-container">
          <div class="course">
            <div class="course-preview">
              <h6>DRS</h6>
              <h2>
                ¡Gracias! Atenderemos tu solicitud lo mas pronto posible
              </h2>
            </div>
            <div class="course-info">
              <h6>Ingeniero Arturo del Rio</h6>
              <h2>Mientras tanto ¡Echale un vistazo a mas productos!</h2>
              <router-link to="/">
                <button class="btn" @click="send">
                  Concluir Proceso
                </button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </design-page>
</template>

<script>
import {
  required,
  minLength,
  email,
  maxLength,
} from "vuelidate/lib/validators";
import DesignPage from "./DesignPage.vue";
import VueRecaptcha from "vue-recaptcha";
import axios from "axios";
import dates from "../assets/json/keys.json";
export default {
  name: "ContactUs",
  components: {
    DesignPage,
    VueRecaptcha,
  },
  data() {
    return {
      name: "",
      lastname: "",
      phone: "",
      email: "",
      message: "",
      ContainerNoticeIsActive: false,
      CardContainerIsActive: true,
      loginForm: {
        recaptchaVerified: false,
        pleaseTickRecaptchaMessage: "",
      },
      sitekey: "6LfNw1wbAAAAANHq00IhEyPF2Uvj7BzFSFccnfgp",
    };
  },
  validations: {
    name: {
      required,
      minLength: minLength(5),
    },
    lastname: {
      required,
      minLength: minLength(10),
    },
    phone: {
      required,
      minLength: minLength(10),
    },
    email: {
      required,
      email: email,
    },
    message: {
      required,
      maxLength: maxLength(250),
    },
    mounted() {
      this.$v.$touch();
    },
  },
  methods: {
    send: function() {
      const testemail = {
        frommail: dates.properties.form.mail,
        password: dates.properties.form.password,
        tomail: dates.properties.form.tomail,
        cliente: {
          name: this.name,
          lastname: this.lastname,
          phone: this.phone,
          email: this.email,
          message: this.message,
        },
      };
      axios.post(dates.properties.api.url, testemail).then();
      this.CardContainerIsActive = false;
      this.ContainerNoticeIsActive = true;
    },
    markRecaptchaAsVerified() {
      this.loginForm.pleaseTickRecaptchaMessage = "";
      this.loginForm.recaptchaVerified = true;
    },
    checkIfRecaptchaVerified() {
      if (!this.loginForm.recaptchaVerified) {
        this.loginForm.pleaseTickRecaptchaMessage =
          "Por favor, marca el Recaptcha";
        return true; 
      }
      this.ContainerNoticeIsActive = true;
      this.CardContainerIsActive= false; 
    },
  },
};
</script>

<style lang="scss" scoped>
.BodyContactUsSolutions {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 90vh;
  width: 100%;
}
.TitleHeaderContactUs {
  font-style: bold;
  font-weight: bold;
  font-size: 1.5em;
  font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
#Cards {
  display: flex;
  width: 1100px;
  height: 350px;
  top: 5%;
  border-radius: 35px;
  box-shadow: 0px 19px 23px rgba(66, 66, 66, 0.452);
}
#Left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 50%;
}
.InputControl {
  width: 70%;
  text-align: center;
}
#Right {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
  width: 50%;
}
.error {
  border-color: red;
  background: #fdd;
}
.error:focus {
  outline-color: #f99;
}
.valid {
  border-color: #5a5;
  background: #efe;
}
.valid:focus {
  outline-color: #8e8;
}
.UnderFormContactUs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
#Recaptcha {
  transform: scale(0.9);
  transform-origin: 0 0;
}
#Button {
  color: black;
  border: none;
  height: 55%;
  width: 30%;
  box-shadow: 0px 4px 15px rgba(35, 15, 55, 0.081);
  font-weight: bold;
}
#Button:hover {
  transition: all 0.55s;
  transform: scale(1.1);
}
#Button::before {
  transition: all 0.55s;
  transform: scale(0);
}
.cookiesContent {
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  color: #000;
  text-align: center;
  border-radius: 20px;
  padding: 30px 30px 70px;
  button.close {
    width: 30px;
    font-size: 20px;
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
    cursor: pointer;
  }
  button.close:hover {
    transition: all 0.55s;
    transform: scale(1.1);
  }
  img {
    width: 82px;
    margin-bottom: 15px;
  }
  p {
    margin-bottom: 40px;
    font-size: 18px;
  }
  button.accept {
    background-image: linear-gradient(to right, #597098, #597);
    border: none;
    border-radius: 5px;
    width: 200px;
    padding: 14px;
    font-size: 16px;
    color: white;
    box-shadow: 0px 6px 18px -5px rgb(0, 0, 0);
    cursor: pointer;
  }
  button.accept:hover {
    transition: all 0.55s;
    transform: scale(1.1);
  }
}
.modal {
  padding-top: 7%;
}
.btn:hover {
  transition: all 0.55s;
  transform: scale(1.1);
}
.Msg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  top: 10%;
  width: 100%;
}
@media (min-width: 280px) and (max-width: 300px) {
  //Smartphones Pequeños : Reduje lineas.
  .TitleHeaderContactUs {
    font-size: 14px;
    margin-left: -17px;
    margin-top: 5%;
  }
  #Cards {
    width: 260px;
    height: 410px;
    left: -10.5px;
  }
  #Left {
    height: 50%;
    width: 100%;
  }
  #Right {
    position: absolute;
    align-self: flex-end;
    height: 50%;
    width: 100%;
  }
  .UnderFormContactUs {
    position: relative;
    top: 8px;
    width: 100%;
    align-items: flex-start;
  }
  #Recaptcha {
    position: relative;
    transform: scale(0.5);
    transform-origin: 0 0;
    top: 20px;
    left: 27%;
  }
  #Button {
    height: 35%;
    width: 30%;
    font-size: 50%;
    top: 25px;
    right: 27%;
  }
}
@media (min-width: 300px) and (max-width: 480px) {
  //Smartphones Promedio
  .BodyContactUsSolutions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90vh;
    width: 100%;
  }
  .TitleHeaderContactUs {
    position: relative;
    font-style: bold;
    font-weight: bold;
    font-size: 18px;
    top: 4%;
    margin-top: -15px;
    font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  #Cards {
    display: flex;
    width: 300px;
    height: 440px;
    border-radius: 35px;
    box-shadow: 0px 19px 23px rgba(66, 66, 66, 0.452);
  }
  #Left {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
    width: 100%;
  }
  .InputControl {
    width: 70%;
    text-align: center;
  }
  #Right {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
    width: 100%;
  }
  .error {
    border-color: red;
    background: #fdd;
  }
  .error:focus {
    outline-color: #f99;
  }
  .valid {
    border-color: #5a5;
    background: #efe;
  }
  .valid:focus {
    outline-color: #8e8;
  }
  .UnderFormContactUs {
    position: relative;
    top: 5%;
    width: 100%;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: space-around;
  }
  #Recaptcha {
    position: relative;
    transform: scale(0.5);
    transform-origin: 0 0;
    top: 20px;
    left: 23%;
  }
  #Button {
    position: relative;
    color: black;
    border: none;
    height: 40%;
    width: 50%;
    box-shadow: 0px 4px 15px rgba(35, 15, 55, 0.081);
    font-weight: bold;
    font-size: 10px;
    top: 25px;
    right: 23%;
  }
}
@media (min-width: 480px) and (max-width: 660px) {
  //Smartphones Grandes
  .BodyContactUsSolutions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90vh;
    width: 100%;
  }
  .TitleHeaderContactUs {
    position: relative;
    font-style: bold;
    font-weight: bold;
    font-size: 20px;
    top: 1%;
    margin-top: 0%;
    font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  #Cards {
    display: flex;
    width: 400px;
    height: 460px;
    border-radius: 35px;
    box-shadow: 0px 19px 23px rgba(66, 66, 66, 0.452);
  }
  #Left {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
    width: 100%;
  }
  .InputControl {
    width: 70%;
    text-align: center;
  }
  #Right {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
    width: 100%;
  }
  .error {
    border-color: red;
    background: #fdd;
  }
  .error:focus {
    outline-color: #f99;
  }
  .valid {
    border-color: #5a5;
    background: #efe;
  }
  .valid:focus {
    outline-color: #8e8;
  }
  .UnderFormContactUs {
    position: relative;
    top: 6%;
    width: 100%;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: space-between;
  }
  #Recaptcha {
    position: relative;
    transform: scale(0.6);
    transform-origin: 0 0;
    top: 15px;
    left: 5%;
  }
  #Button {
    position: relative;
    color: black;
    border: none;
    height: 45%;
    width: 30%;
    box-shadow: 0px 4px 15px rgba(35, 15, 55, 0.081);
    font-weight: bold;
    font-size: 13px;
    top: 20px;
    right: 13%;
  }
}
@media (min-width: 660px) and (max-width: 980px) {
  //Tabletas
  .BodyContactUsSolutions {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 90vh;
    width: 100%;
  }
  .TitleHeaderContactUs {
    position: relative;
    font-style: bold;
    font-weight: bold;
    font-size: 20px;
    top: 3%;
    font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  }
  #Cards {
    display: flex;
    width: 620px;
    height: 430px;
    border-radius: 35px;
    box-shadow: 0px 19px 23px rgba(66, 66, 66, 0.452);
  }
  #Left {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
    width: 100%;
  }
  .InputControl {
    width: 70%;
    text-align: center;
  }
  #Right {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: flex-end;
    flex-direction: column;
    justify-content: space-around;
    height: 50%;
    width: 100%;
  }
  .error {
    border-color: red;
    background: #fdd;
  }
  .error:focus {
    outline-color: #f99;
  }
  .valid {
    border-color: #5a5;
    background: #efe;
  }
  .valid:focus {
    outline-color: #8e8;
  }
  .UnderFormContactUs {
    position: relative;
    top: 6%;
    width: 100%;
    display: flex;
    align-self: flex-start;
    align-items: flex-start;
    justify-content: space-between;
  }
  #Recaptcha {
    position: relative;
    transform: scale(0.8);
    transform-origin: 0 0;
    top: 5px;
    left: 10%;
  }
  #Button {
    position: relative;
    color: black;
    border: none;
    height: 60%;
    width: 30%;
    box-shadow: 0px 4px 15px rgba(35, 15, 55, 0.081);
    font-weight: bold;
    font-size: 15px;
    top: 15px;
    right: 10%;
  }
}
@media (min-width: 980px) and (max-width: 1024px) {
  //IPads/Tablets Grandes
  .TitleHeaderContactUs {
    font-size: 30px;
    top: 3%;
  }
  #Cards {
    width: 620px;
    height: 730px;
  }
  #Left {
    height: 50%;
    width: 100%;
  }
  #Right {
    position: absolute;
    align-self: flex-end;
    height: 50%;
    width: 100%;
  }
  .UnderFormContactUs {
    position: relative;
    top: 6%;
    width: 100%;
    align-items: flex-start;
    justify-content: space-between;
  }
  #Recaptcha {
    position: relative;
    transform: scale(0.8);
    transform-origin: 0 0;
    top: 5px;
    left: 10%;
  }
  #Button {
    height: 60%;
    width: 30%;
    font-size: 15px;
    top: 15px;
    right: 10%;
  }
}
@media (min-width: 320px) and (max-width: 320px) {
  //Resolucion especifica IPhone
  .TitleHeaderContactUs {
    font-size: 14px;
    margin-top: 5%;
  }
  #Cards {
    width: 260px;
    height: 380px;
  }
  #Recaptcha {
    position: relative;
    transform: scale(0.5);
    transform-origin: 0 0;
    top: 20px;
    left: 27%;
  }
  #Button {
    height: 35%;
    width: 30%;
    font-size: 50%;
    top: 25px;
    right: 27%;
  }
}
.TitleHeaderExitView {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-style: bold;
  font-weight: bold;
  font-size: 1.5em;
  font-family: "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}
.ContainerNoticeViewExit {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 70%;
}
.course {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0px 19px 23px rgba(95, 95, 95, 0.452);
  display: flex;
  max-width: 100%;
  margin: 20px;
  overflow: hidden;
  width: 700px;
}
.course h6 {
  opacity: 0.6;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #fff;
}
.course h2 {
  letter-spacing: 1px;
  margin: 10px 0;
  color: #fff;
  font-size: 1.4em;
}
.course-preview {
  background-image: linear-gradient(to right, #597098, #597);
  color: #fff;
  padding: 30px;
  max-width: 250px;
}
.course-info {
  padding: 30px;
  position: relative;
  width: 100%;
}
.course-info h2 {
  letter-spacing: 1px;
  margin: 10px 0;
  color: black;
}
.course-info h6 {
  letter-spacing: 1px;
  margin: 10px 0;
  color: black;
}
.btn {
  background-image: linear-gradient(to right, #597098, #597);
  border: 0;
  border-radius: 50px;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 16px;
  padding: 12px 25px;
  position: absolute;
  bottom: 30px;
  right: 30px;
  letter-spacing: 1px;
  cursor: pointer;
}
@media (min-width: 200px) and (max-width: 300px) {
  .BodyExitViewSolutions {
    width: 100%;
    height: 100vh;
  }
  .CenterContainerExitView {
    width: 100%;
    height: 100%;
  }
  .ContainerNoticeViewExit {
    width: 100%;
    height: 90%;
  }
  .TitleHeaderExitView {
    font-size: 0.9em;
    width: 100%;
    top: 5%;
  }
  .TitleHeaderExitView h1 {
    position: relative;
    left: -4%;
  }
  .course {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 95%;
    left: -4%;
    top: 5%;
  }
  .btn {
    position: relative;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    top: 10px;
    left: 5%;
  }
}
@media (min-width: 300px) and (max-width: 480px) {
  .BodyExitViewSolutions {
    width: 100%;
    height: 100vh;
  }
  .CenterContainerExitView {
    width: 100%;
    height: 100%;
  }
  .ContainerNoticeViewExit {
    width: 100%;
    height: 90%;
  }
  .TitleHeaderExitView {
    font-size: 0.9em;
    width: 100%;
    top: 5%;
  }
  .TitleHeaderExitView h1 {
    position: relative;
  }
  .course {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 95%;
    top: 5%;
  }
  .btn {
    position: relative;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    top: 10px;
    left: 4%;
  }
}
@media (min-width: 480px) and (max-width: 660px) {
  .BodyExitViewSolutions {
    width: 100%;
    height: 100vh;
  }
  .CenterContainerExitView {
    width: 100%;
    height: 100%;
  }
  .ContainerNoticeViewExit {
    width: 100%;
    height: 90%;
  }
  .TitleHeaderExitView {
    font-size: 0.9em;
    width: 100%;
    top: 5%;
  }
  .TitleHeaderExitView h1 {
    position: relative;
  }
  .course {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 95%;
    top: 5%;
  }
  .btn {
    position: relative;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    top: 10px;
    left: 4%;
  }
}
@media (min-width: 320px) and (max-width: 320px) {
  //IPhone 5/SE, resolucion especifica.
  .BodyExitViewSolutions {
    height: 110vh;
  }
  .TitleHeaderExitView {
    font-size: 0.9em;
    width: 100%;
    top: 7%;
  }
  .course {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 235px;
    height: 92%;
    top: 5%;
  }
  .btn {
    position: relative;
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: space-between;
    top: 10px;
    left: 4%;
  }
}
</style>
